<template>
    <div :class="styleClass">
        <input
            :value="modelValue"
            @input="updateValue"
            type="text"
            :placeholder="placeholder"
        />
        <img role="img" :src="iconSrc" />
    </div>
</template>

<script setup lang="ts">
import { defineEmits } from "vue";

defineProps<{
    styleClass?: string;
    modelValue?: string;
    placeholder?: string;
    iconSrc?: any;
}>();

const emit = defineEmits(["update:modelValue"]);
const updateValue = (event: any) => {
    emit("update:modelValue", event.target.value);
};
</script>
