export const isEmptyOrUndefinedString = (variable: any) => {
    return ["undefined", undefined, "null", null, ""].includes(variable);
};

export const getValidationMessages = (model) => {
    /**
     * Returns a list of validation messages for each input if the validation
     * is invalid.
     */
    const errors: string[] = [];
    if (!model.$dirty) return errors;
    else {
        if (model.$error) {
            if (model.required && model.required.$invalid) {
                errors.push("This value is required");
            }
            if (model.fileSizeLimit && model.fileSizeLimit.$invalid) {
                errors.push(model.fileSizeLimit.$message);
            }
            if (model.validBirthdate && model.validBirthdate.$invalid) {
                errors.push(model.validBirthdate.$message);
            }
            if (model.minValue && model.minValue.$invalid) {
                errors.push(model.minValue.$message);
            }
            if (model.maxValue && model.maxValue.$invalid) {
                errors.push(model.maxValue.$message);
            }
            if (model.url && model.url.$invalid) {
                errors.push(model.url.$message);
            }
            if (model.maxLength && model.maxLength.$invalid) {
                errors.push(model.maxLength.$message);
            }
            if (model.email && model.email.$invalid) {
                errors.push(model.email.$message);
            }
            if (model.validShortcode && model.validShortcode.$invalid) {
                errors.push(model.validShortcode.$message);
            }
        }
        return errors;
    }
};

const convertToBytes = (unit: "kB" | "MB" | "GB", limit: number): number => {
    /**
     * Takes a unit (kB, MB, GB) and a size limit as parameters. Based on the unit provided,
     * it multiplies the size limit by the appropriate factor to convert it to bytes. Then,
     * it returns the calculated size limit in bytes.
     * @param {"kB" | "MB" | "GB"} unit
     * @param {number} limit
     * @returns {number}
     */
    switch (unit) {
        case "kB":
            return limit * 1024;
        case "MB":
            return limit * 1024 * 1024;
        case "GB":
            return limit * 1024 * 1024 * 1024;
        default:
            return 0;
    }
};

export const validateFileSize = (
    files: any,
    unit: "kB" | "MB" | "GB",
    sizeLimit: number,
): { valid: boolean; message: string } => {
    /**
     * Validates if any of the uploaded files sizes it's bigger than
     * the file limit and returns a validation error.
     * @param {any} files
     * @param {"kB" | "MB" | "GB"} unit
     * @param {number} sizeLimit
     * @returns  { valid: boolean; message: string }
     */
    let valid = true;
    const fileNames: string[] = [];
    const limit = convertToBytes(unit, sizeLimit);

    for (let i = 0; i < files.length; i++) {
        valid = files[i].size < limit;
        fileNames.push(files[i].name);
    }
    return {
        valid: valid,
        message: valid
            ? ""
            : `The file${fileNames.length > 1 ? "s" : ""} ${fileNames} exceed${fileNames.length > 1 ? "" : "s"} the ${sizeLimit}${unit} individual file size`,
    };
};

export const validateBirthdate = (
    birthdate: string,
): { valid: boolean; message: string } => {
    /**
     * Validates if the birthdate is in the DD-MM-YYYY format and it's a valid date
     * @param {string} birthdate
     * @returns  { valid: boolean; message: string }
     */
    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
    if (!dateRegex.test(birthdate)) {
        return {
            valid: false,
            message: "Invalid date format (DD-MM-YYYY)",
        };
    }

    const [day, month, year] = birthdate.split("-").map(Number);
    /** month - 1 because months are zero-based in JavaScript */
    const date = new Date(year, month - 1, day);

    if (
        date.getDate() !== day ||
        date.getMonth() + 1 !== month ||
        date.getFullYear() !== year
    ) {
        return {
            valid: false,
            message: "Invalid date",
        };
    }

    /** Check if the date is in the past */
    const currentDate = new Date();
    if (date >= currentDate) {
        return {
            valid: false,
            message: "Birthdate must be in the past",
        };
    }

    return {
        valid: true,
        message: "",
    };
};

export const validateShortcode = (
    shortcode: string,
): { valid: boolean; message: string } => {
    /**
     * Validates if the shortcode is in the correct format
     * @param {string} shortcode
     * @returns  { valid: boolean; message: string }
     */
    const shortcodeRegex = /^[a-z]+(-[a-z]+)*$/;
    if (!shortcodeRegex.test(shortcode)) {
        return {
            valid: false,
            message:
                "Invalid shortcode format, please only use lowercase letters and hyphens.",
        };
    }
    return {
        valid: true,
        message: "",
    };
};
