export type SelectorObject = {
    title: string;
    value: string | boolean | number | null;
};

export type StatusObject = {
    status: string;
    count: number;
};

export interface ErrorObject {
    message: string;
    status?: string;
    identifier?: string;
    code: string;
}

export interface ErrorHandlerConfig {
    userAction: string;
    sentryTransactionName: string;
    disable404Alert?: boolean;
    redirectTo?: string[];
}

export class DownloadError extends Error {
    status: string;
    identifier: string;
    code: string;

    constructor(
        status: string,
        message: string,
        identifier: string = "",
        code: string = "",
    ) {
        super(message);
        this.status = status;
        this.identifier = identifier;
        this.code = code;
        this.name = "DownloadError";
    }
}
