export enum BACSInstructionType {
    MANDATE = "mandate",
    DIRECT_DEBIT = "direct_debit",
    DIRECT_CREDIT_SUPPLIER = "direct_credit_supplier",
    DIRECT_CREDIT_PAYROLL = "direct_credit_payroll",
    NONE = "none",
}

export const BACSInstructionTypeKebabCase = {
    mandate: "mandate",
    direct_debit: "direct-debit",
    direct_credit_supplier: "direct-credit-supplier",
    direct_credit_payroll: "direct-credit-payroll",
};

export const MapBACSRouteToInstructionType = new Map<
    string,
    BACSInstructionType
>([
    ["mandate", BACSInstructionType.MANDATE],
    ["direct-debit", BACSInstructionType.DIRECT_DEBIT],
    ["direct-credit-payroll", BACSInstructionType.DIRECT_CREDIT_PAYROLL],
    ["direct-credit-supplier", BACSInstructionType.DIRECT_CREDIT_SUPPLIER],
]);

export enum BACSFileStatus {
    CREATED = "created",
    PROCESSED = "processed",
    RECEIVED = "received",
    DUPLICATE = "duplicate",
    IN_REVIEW = "in_review",
    UNKNOWN = "unknown",
    NONE = "",
}

export enum BACSStatusGroup {
    APPROVED_AND_COMPLETED = "approved_completed",
    ACTION_REQUIRED = "action_required",
    FAILED_AND_REJECTED = "failed_rejected_dupe",
    EXCLUDED = "excluded",
    INCLUDED = "included",
    CREATED = "created",
    UNACTIONED = "unactioned",
    ALL = "all",
    NONE = "",
}

export const BACSTabsItems = [
    {
        title: "View All",
        route: BACSStatusGroup.ALL,
        icon: "mdi-dots-horizontal-circle-outline",
        iconColor: "#D7D8DF",
    },
    {
        title: "Action Required",
        route: BACSStatusGroup.ACTION_REQUIRED,
        icon: "mdi-asterisk-circle-outline",
        iconColor: "#67CBFF",
    },
    {
        title: "Approved & Completed",
        route: BACSStatusGroup.APPROVED_AND_COMPLETED,
        icon: "mdi-check-circle-outline",
        iconColor: "#159D50",
    },
    {
        title: "Failed, Rejected & Ignored",
        route: BACSStatusGroup.FAILED_AND_REJECTED,
        icon: "mdi-close-circle-outline",
        iconColor: "#F74141",
    },
];

export enum BACSAction {
    APPROVE = "approve",
    UNAPPROVE = "unapprove",
    APPROVE_WITH_NOTIFICATION = "approve_with_notification",
    SECOND_APPROVAL = "second_approval",
    REJECT = "reject",
    UPDATE_COLLECTION_DATE = "update_collection_date",
    UPDATE_PAYMENT_DATE = "update_payment_date",
    REMOVE_INVALID_REFERENCES = "remove_invalid_references",
    RESCHEDULE = "reschedule",
    REMOVE_REFERENCE = "remove_reference",
    INCLUDE = "include",
    EXCLUDE = "exclude",
    NONE = "",
}

export enum BACSInstructionStatus {
    CREATED = "created",
    AWAITING_APPROVAL = "awaiting_approval",
    AWAITING_ACTION = "awaiting_action",
    VALID = "valid",
    APPROVED = "approved",
    INCLUDED = "included",
    REJECTED = "rejected",
    EXCLUDED = "excluded",
    DUPLICATE = "duplicate",
    OVERDUE = "overdue",
    INVALID_INSTRUCTION = "invalid_instruction",
    INVALID_DATE = "invalid_date",
    INVALID_REFERENCE = "invalid_reference",
    INVALID_ACCOUNT_NAME = "invalid_account_name",
    INVALID_ACCOUNT_NUMBER = "invalid_account_number",
    INVALID_SORT_CODE = "invalid_sort_code",
    FAILED_VALIDATION = "failed_validation",
    MANDATE_REQUIRED = "mandate_required",
    COMPLETED = "completed",
    AWAITING_SECOND_APPROVAL = "awaiting_second_approval",
    AWAITING_APPROVAL_TO_CANCEL = "awaiting_approval_to_cancel",
    FAILED = "failed",
    DELETED = "deleted",
    IGNORED = "ignored",
    ALL = "all",
    NONE = "",
}

export const BacsInstructionRoutes = {
    mandate: "MandatesInstructions",
    direct_debit: "DirectDebitsInstructions",
    direct_credit_supplier: "DirectCreditSupplierInstructions",
    direct_credit_payroll: "DirectCreditPayrollInstructions",
};

export const BacsInstructionFilesRoutes = {
    mandate: "MandateFiles",
    direct_debit: "DirectDebitsFiles",
    direct_credit_supplier: "DirectCreditSupplierFiles",
    direct_credit_payroll: "DirectCreditPayrollFiles",
};

export enum BACSBatchStatus {
    CREATED = "created",
    DRAFT = "draft",
    PART_APPROVED = "part_approved",
    APPROVED = "approved",
    SEND_TO_BUREAU = "sent_to_bureau",
    DISCARDED = "discarded",
}

export enum BatchSteps {
    BATCH_INFORMATION = "batch-information", // add name/create batch
    SELECT_ACCOUNT = "select-account",
    INSTRUCTIONS = "instructions",
    REVIEW_APPROVALS = "review-approvals",
    SEND_TO_BUREAU = "send-to-bureau",
    SUMMARY = "summary",
}

export enum BatchRouteNames {
    BATCH_INFORMATION = "BatchInformation",
    SELECT_ACCOUNT = "SelectAccount",
    INSTRUCTIONS = "Instructions",
    REVIEW_APPROVALS = "ReviewApprovals",
    SEND_TO_BUREAU = "SendToBureau",
    SUMMARY = "BatchSummary",
}

export const mapBatchRoutes = new Map<BatchSteps, BatchRouteNames>([
    [BatchSteps.BATCH_INFORMATION, BatchRouteNames.BATCH_INFORMATION],
    [BatchSteps.SELECT_ACCOUNT, BatchRouteNames.SELECT_ACCOUNT],
    [BatchSteps.INSTRUCTIONS, BatchRouteNames.INSTRUCTIONS],
    [BatchSteps.REVIEW_APPROVALS, BatchRouteNames.REVIEW_APPROVALS],
    [BatchSteps.SEND_TO_BUREAU, BatchRouteNames.SEND_TO_BUREAU],
    [BatchSteps.SUMMARY, BatchRouteNames.SUMMARY],
]);

export const mapBatchSteps = new Map<BatchSteps, number>([
    [BatchSteps.BATCH_INFORMATION, 1],
    [BatchSteps.SELECT_ACCOUNT, 2],
    [BatchSteps.INSTRUCTIONS, 3],
    [BatchSteps.REVIEW_APPROVALS, 4],
    [BatchSteps.SEND_TO_BUREAU, 5],
    [BatchSteps.SUMMARY, 6],
]);

/** Created a new one in case the status group changes for batches */
export const AllBatchInstructionsTabsItems = [
    {
        title: "View All",
        route: BACSStatusGroup.ALL,
        icon: "mdi-dots-horizontal-circle-outline",
        iconColor: "#D7D8DF",
    },
    {
        title: "Action Required",
        route: BACSStatusGroup.UNACTIONED,
        icon: "mdi-asterisk-circle-outline",
        iconColor: "#67CBFF",
    },
    {
        title: "Included",
        route: BACSStatusGroup.INCLUDED,
        icon: "mdi-check-circle-outline",
        iconColor: "#159D50",
    },
    {
        title: "Excluded",
        route: BACSStatusGroup.EXCLUDED,
        icon: "mdi-close-circle-outline",
        iconColor: "#F74141",
    },
];
