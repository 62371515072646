import { authGuard } from "@auth0/auth0-vue";
import { RouteRecordRaw } from "vue-router";

const registrationRoutes: Array<RouteRecordRaw> = [
    {
        path: "/registration",
        name: "Registration",
        beforeEnter: authGuard,
        component: () => import("@/views/registration/Registration.vue"),
        meta: {
            admin: false,
            school: false,
        },
        children: [
            {
                path: "company-details",
                name: "CompanyDetails",
                beforeEnter: authGuard,
                component: () =>
                    import("@/components/registration/companyDetails.vue"),
                meta: {
                    admin: false,
                    school: false,
                },
            },
            {
                path: "account-usage",
                name: "AccountUsage",
                beforeEnter: authGuard,
                component: () =>
                    import("@/components/registration/accountUsage.vue"),
                meta: {
                    admin: false,
                    school: false,
                },
            },
            {
                path: "stakeholders",
                name: "Stakeholders",
                beforeEnter: authGuard,
                component: () =>
                    import("@/components/registration/stakeholders.vue"),
                meta: {
                    admin: false,
                    school: false,
                },
            },
            {
                path: "documents",
                name: "Documents",
                beforeEnter: authGuard,
                component: () =>
                    import("@/components/registration/documents.vue"),
                meta: {
                    admin: false,
                    school: false,
                },
            },
            {
                path: "summary",
                name: "Summary",
                beforeEnter: authGuard,
                component: () =>
                    import("@/components/registration/summary.vue"),
                meta: {
                    admin: false,
                    school: false,
                },
            },
            {
                path: "application-complete",
                name: "ApplicationComplete",
                beforeEnter: authGuard,
                component: () =>
                    import("@/components/registration/applicationComplete.vue"),
                meta: {
                    admin: false,
                    school: false,
                },
            },
        ],
    },
];

export default registrationRoutes.map((route) => {
    return { ...route };
});
