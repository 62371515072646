import { changeRoute } from "@/router";
import { Permissions } from "@/constants/access";

export const checkIfArrayElementsMatch = (
    existingRoles: string[],
    storedRoles: any,
) => {
    return existingRoles.some((r) => storedRoles.indexOf(r) >= 0);
};

export const redirectUserBasedOnPermissions = (
    userPermissions: any = null,
    origin: any,
) => {
    const admin = [Permissions.ESENDA_ADMIN];

    if (userPermissions.length > 0) {
        if (checkIfArrayElementsMatch(admin, userPermissions)) {
            changeRoute("/admin/create-organisation");
        } else {
            // For school users, if an origin is provided we will redirect to it
            // when they are logged in. This is to enable the email download link
            // to work
            if (origin) {
                changeRoute(origin);
            } else {
                changeRoute("/school/overview");
            }
        }
    } else {
        changeRoute("/registration/company-details");
    }
};
