<template>
    <div></div>
    <v-spacer></v-spacer>
    <div class="user-container mr-3">
        <!-- user logo -->
        <v-img class="user-logo" :src="userPicture"></v-img>
        <!-- user details -->
        <div class="user-details-container">
            <div
                data-cy="userName"
                class="user-name body-medium white-text mt-2"
            >
                {{ userName }}
            </div>
        </div>
        <!-- settings button -->
        <div class="settings-container">
            <icon-button
                data-cy="userMenuButton"
                btn-style="default-btn medium-icon-btn transparent-btn"
                :icon-src="getImageUrl('icons/20x20/ic20-log-out.svg')"
                @run-method="handleLogout()"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useAuth0 } from "@auth0/auth0-vue";
import { computed } from "vue";
import iconButton from "@/components/buttons/iconButton.vue";
import { getImageUrl } from "@/methods/iconHelpers";

// gets current user
const { user } = useAuth0();
const { logout } = useAuth0();

// get user details
const userPicture = computed(() => (user.value ? user.value.picture : ""));
const userName = computed(() => (user.value ? user.value.name : ""));

// logs user out
const handleLogout = () =>
    logout({
        logoutParams: {
            returnTo: window.location.origin,
        },
    });
</script>
