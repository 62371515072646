<template>
    <v-layout>
        <!-- large screens navigation drawer -->
        <v-navigation-drawer
            class="navbar"
            permanent
            v-if="isAuthenticated && display.mdAndUp"
        >
            <navbar />
        </v-navigation-drawer>

        <v-main>
            <!-- app bar-->
            <v-app-bar v-if="isAuthenticated" class="appbar" elevation="0">
                <!-- trigger for mobile navigation -->
                <icon-button
                    v-if="display.smAndDown"
                    data-cy="mobileMenuButton"
                    btn-style="default-btn medium-icon-btn transparent-btn ml-5"
                    :icon-src="getImageUrl('icons/20x20/ic20-menu.svg')"
                    @run-method="drawer = !drawer"
                />
                <!-- TODO add logic for admin app bar-->
                <school-app-bar v-if="hasOrganisationRole" />
                <registration-app-bar v-else />
            </v-app-bar>

            <!-- mobile navigation drawer-->
            <v-navigation-drawer
                v-model="drawer"
                location="start"
                temporary
                v-if="isAuthenticated && display.smAndDown"
                class="mobile-navbar"
            >
                <navbar />
            </v-navigation-drawer>
            <!-- page content -->
            <v-container class="main" fluid>
                <div v-if="isLoading" class="center-screen-app">
                    <loading
                        size="48"
                        :show-loading-text="false"
                        style-class="ma-auto"
                    />
                </div>
                <router-view v-else />
                <alert
                    v-model="alertConfiguration.visible"
                    :alert-message="alertConfiguration.message"
                    :alert-color="alertConfiguration.color"
                    :alert-parameters="alertConfiguration.alertParameters"
                ></alert>

                <updated-access-pop-up
                    :dialog-visible="showAccessPopUp"
                    @update:dialog-visible="updatePopUpVisible"
                ></updated-access-pop-up>
            </v-container>
        </v-main>
    </v-layout>
</template>

<script setup lang="ts">
// auth buttons
import navbar from "@/components/structural/NavBar.vue";
import SchoolAppBar from "@/components/structural/SchoolAppBar.vue";
import RegistrationAppBar from "./components/structural/RegistrationAppBar.vue";
import loading from "@/components/misc/loading.vue";
import iconButton from "@/components/buttons/iconButton.vue";
import alert from "@/components/misc/alert.vue";
import updatedAccessPopUp from "@/components/structural/updatedAccessPopUp.vue";

import { alertConfiguration } from "./methods/errorHandling";
import { useDisplay } from "vuetify";
import { useAuth0 } from "@auth0/auth0-vue";
import { ref, watch, onMounted, computed, onBeforeUnmount } from "vue";
import { usePiniaStore } from "./stores/store";
import { getImageUrl } from "@/methods/iconHelpers";
import { setSentryUser } from "./methods/sentry";
import { isEmptyOrUndefinedString } from "./methods/validationRules";
import { Roles } from "@/constants/access";
import { checkUserPermissions } from "@/apis/esendaV2/user";

const { isLoading, isAuthenticated, user, getAccessTokenSilently } = useAuth0();

const pStore = usePiniaStore();

// breakpoints
const display = ref(useDisplay());
// mobile navigation drawer
const drawer = ref(false);

const hasOrganisationRole = computed(() =>
    pStore.getUserRoles.includes(Roles.ORGANISATION_ROLE),
);

const isHubpay = () => {
    const host = window.location.host;
    pStore.setIsHubpay(host.includes("hubpay"));
};

isHubpay();

const token = ref("");
const getAccessTokenFromAuth0 = async () => {
    token.value = await getAccessTokenSilently();
};

const showAccessPopUp = ref(false);
const updatePopUpVisible = (value: boolean) => {
    showAccessPopUp.value = value;
};
const getPermissions = async () => {
    const userEmail = user.value ? String(user.value.email) : null;
    if (userEmail !== null) {
        const updated = await checkUserPermissions(token.value, userEmail);
        showAccessPopUp.value = updated;
    }
};

let intervalId;
const runIntervalPermissionsCheck = () => {
    /** Check permissions every 1 min */
    intervalId = setInterval(() => {
        if (token.value) {
            getPermissions();
        }
    }, 60000);
};

watch(isAuthenticated, (currentValue) => {
    pStore.setIsAuthenticated(Boolean(currentValue));
    if (currentValue) {
        getAccessTokenFromAuth0();
    }
});

onMounted(() => {
    /** Update the user email in sentry if the auth email is not empty */
    const userEmail = user.value ? String(user.value.email) : "";
    if (!isEmptyOrUndefinedString(userEmail)) {
        setSentryUser(userEmail);
    }
});

runIntervalPermissionsCheck();

onBeforeUnmount(() => {
    /** Clean up the interval when the component is destroyed */
    if (intervalId) {
        clearInterval(intervalId);
    }
});
</script>
