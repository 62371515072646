import { apiServerUrl, ExternalApi } from "@/apis/apiService";
import { usePiniaStore } from "@/stores/store";
import { areArraysEquivalent } from "@/methods/arrayProcessors";

export const getUserProfile = async (token: string, email: string) => {
    /**
     * Returns the profile information for based on the email address
     * It saves the organisation information in the session store.
     * @param {string} email
     */
    const pStore = usePiniaStore();

    const formData = new FormData();
    formData.append("email", email);

    const config = {
        url: `${apiServerUrl}/portal-user/profile`,
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: formData,
    };

    /** Define the behaviour if an error occurs during the API call */
    const errorHandlerConfig = {
        userAction: "refresh", // user needs to retry the action
        sentryTransactionName: "getUserProfile",
        redirectTo: ["access-denied"],
    };
    const externalApi = new ExternalApi();
    const { data } = await externalApi.callExternalApi(
        { config },
        errorHandlerConfig,
    );
    if (data !== null) {
        /**
         * Saves the role, shortcode & permissions to the store
         */
        pStore.setUserRoles(data["role"]);

        if ("shortcode" in data && data["shortcode"] !== null) {
            pStore.setSchoolShortcodes([data["shortcode"]]);
        } else {
            pStore.setSchoolShortcodes([]);
        }

        const permissionsList =
            "permissions" in data ? data["permissions"] : [];
        pStore.setPermissions(permissionsList);

        pStore.setSchoolSettings(data);

        return data;
    } else {
        return null;
    }
};

export const checkUserPermissions = async (token: any, email: string) => {
    /**
     * Returns the user's permissions for based on the email address
     * It compares the permissions to the stored values
     * @param {string} email
     */
    const pStore = usePiniaStore();

    const formData = new FormData();
    formData.append("email", email);

    const config = {
        url: `${apiServerUrl}/portal-user/profile`,
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: formData,
    };

    /** Define the behaviour if an error occurs during the API call */
    const errorHandlerConfig = {
        userAction: "ignore", // user needs to retry the action
        sentryTransactionName: "checkUserPermissions",
        disable404Alert: true, // don't display the error message if the permissions can't be found
    };
    const externalApi = new ExternalApi();
    const { data } = await externalApi.callExternalApi(
        { config },
        errorHandlerConfig,
    );

    let permissionsUpdated = false;
    if (data !== null) {
        /**
         * Compares the stored permissions to the api's response
         */
        const currentPermissions =
            pStore.getPermissions === null
                ? []
                : Array.from(pStore.getPermissions);
        const updatedPermissions =
            "permissions" in data ? data["permissions"] : [];

        permissionsUpdated = !areArraysEquivalent(
            currentPermissions,
            updatedPermissions,
        );
    }

    return permissionsUpdated;
};
