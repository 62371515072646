export enum Roles {
    ORGANISATION_ROLE = "v2-school-admin",
    ADMIN_ROLE = "v2-admin",
}

export enum Permissions {
    MANDATE = "mandate",
    DIRECT_DEBIT = "direct_debit",
    SUPPLIER = "supplier",
    PAYROLL = "payroll",
    ESENDA_ADMIN = "esenda_admin",
}

export enum OrganisationPermissions {
    BACS = "bacs",
    WCBS = "wcbs",
}
