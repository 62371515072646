import { getImageUrl } from "@/methods/iconHelpers";
import { Permissions, OrganisationPermissions } from "@/constants/access";

export const navSchoolRoutes = [
    {
        title: "Overview",
        iconPath: getImageUrl("icons/24x24/ic24-home.svg"),
        route: "/school/overview",
        requiredPermissions: [],
        multiplePaths: false,
        requiredOrganisationPermissions: [],
    },
    {
        title: "Invoices",
        iconPath: getImageUrl("icons/24x24/ic24-file.svg"),
        route: "/school/invoices",
        requiredPermissions: [],
        multiplePaths: false,
        requiredOrganisationPermissions: [],
    },
    {
        title: "Transactions",
        iconPath: getImageUrl("icons/24x24/ic24-transaction.svg"),
        route: "/school/transactions",
        requiredPermissions: [],
        multiplePaths: false,
        requiredOrganisationPermissions: [],
        subitems: [
            {
                title: "Invoice Payments",
                iconPath: getImageUrl("icons/16x16/ic16-placeholder.svg"),
                route: "/school/transactions/invoice-payments",
                requiredPermissions: [],
                multiplePaths: false,
                requiredOrganisationPermissions: [],
            },
            {
                title: "Extras Payments",
                iconPath: getImageUrl("icons/16x16/ic16-placeholder.svg"),
                route: "/school/transactions/extras",
                requiredPermissions: [],
                multiplePaths: false,
                requiredOrganisationPermissions: [],
            },
            {
                title: "Donations",
                iconPath: getImageUrl("icons/16x16/ic16-placeholder.svg"),
                route: "/school/transactions/donations",
                requiredPermissions: [],
                multiplePaths: false,
                requiredOrganisationPermissions: [],
            },
            {
                title: "Direct Debit",
                iconPath: getImageUrl("icons/16x16/ic16-placeholder.svg"),
                route: "/school/transactions/direct-debits",
                requiredPermissions: [Permissions.DIRECT_DEBIT],
                multiplePaths: false,
                requiredOrganisationPermissions: [OrganisationPermissions.BACS],
            },
            {
                title: "Direct Credit Payroll",
                iconPath: getImageUrl("icons/16x16/ic16-placeholder.svg"),
                route: "/school/transactions/direct-credit-payroll",
                requiredPermissions: [Permissions.PAYROLL],
                multiplePaths: false,
                requiredOrganisationPermissions: [OrganisationPermissions.BACS],
            },
            {
                title: "Direct Credit Supplier",
                iconPath: getImageUrl("icons/16x16/ic16-placeholder.svg"),
                route: "/school/transactions/direct-credit-supplier",
                requiredPermissions: [Permissions.SUPPLIER],
                multiplePaths: false,
                requiredOrganisationPermissions: [OrganisationPermissions.BACS],
            },
        ],
    },
    {
        title: "Posting report",
        iconPath: getImageUrl("icons/24x24/ic24-upload.svg"),
        route: "/school/posting-report",
        requiredPermissions: [],
        multiplePaths: false,
        requiredOrganisationPermissions: [OrganisationPermissions.WCBS],
    },
    {
        title: "Payment links",
        iconPath: getImageUrl("icons/24x24/ic24-credit-card.svg"),
        route: "/school/payment-request-links",
        requiredPermissions: [],
        multiplePaths: false,
        requiredOrganisationPermissions: [],
    },
    {
        title: "Pass finance",
        iconPath: getImageUrl("icons/24x24/ic24-download.svg"),
        route: "/school/export/pass-finance",
        requiredPermissions: [],
        multiplePaths: false,
        requiredOrganisationPermissions: [],
    },
    // {
    //     title: "Connected banks",
    //     iconPath: getImageUrl("icons/24x24/ic24-company.svg"),
    //     route: "/school/connected-banks",
    // },
    {
        title: "BACS actions",
        iconPath: getImageUrl("icons/24x24/ic24-wallet.svg"),
        route: "/school/bacs/mandates",
        requiredPermissions: [],
        multiplePaths: false,
        requiredOrganisationPermissions: [OrganisationPermissions.BACS],
        subitems: [
            {
                title: "Mandates",
                iconPath: getImageUrl("icons/16x16/ic16-placeholder.svg"),
                route: "/school/bacs/mandate/batches",
                requiredPermissions: [Permissions.MANDATE],
                multiplePaths: true,
                matchPath: [
                    /\/school\/bacs\/mandate\/batches/,
                    /\/school\/bacs\/mandate\/batches\/[a-f0-9-]+\/batch-information$/,
                    /\/school\/bacs\/mandate\/batches\/[a-f0-9-]+\/select-account$/,
                    /\/school\/bacs\/mandate\/batches\/[a-f0-9-]+\/instructions$/,
                    /\/school\/bacs\/mandate\/batches\/[a-f0-9-]+\/review-approvals$/,
                    /\/school\/bacs\/mandate\/batches\/[a-f0-9-]+\/send-to-bureau$/,
                ],
                requiredOrganisationPermissions: [OrganisationPermissions.BACS],
            },
            {
                title: "Direct Debit",
                iconPath: getImageUrl("icons/16x16/ic16-placeholder.svg"),
                route: "/school/bacs/direct-debit/batches",
                requiredPermissions: [Permissions.DIRECT_DEBIT],
                multiplePaths: true,
                matchPath: [
                    /\/school\/bacs\/direct-debit\/batches/,
                    /\/school\/bacs\/direct-debit\/batches\/[a-f0-9-]+\/batch-information$/,
                    /\/school\/bacs\/direct-debit\/batches\/[a-f0-9-]+\/select-account$/,
                    /\/school\/bacs\/direct-debit\/batches\/[a-f0-9-]+\/instructions$/,
                    /\/school\/bacs\/direct-debit\/batches\/[a-f0-9-]+\/review-approvals$/,
                    /\/school\/bacs\/direct-debit\/batches\/[a-f0-9-]+\/send-to-bureau$/,
                ],
                requiredOrganisationPermissions: [OrganisationPermissions.BACS],
            },
            {
                title: "Payroll Payments",
                iconPath: getImageUrl("icons/16x16/ic16-placeholder.svg"),
                route: "/school/bacs/direct-credit-payroll/batches",
                requiredPermissions: [Permissions.PAYROLL],
                multiplePaths: true,
                matchPath: [
                    /\/school\/bacs\/direct-credit-payroll\/batches/,
                    /\/school\/bacs\/direct-credit-payroll\/batches\/[a-f0-9-]+\/batch-information$/,
                    /\/school\/bacs\/direct-credit-payroll\/batches\/[a-f0-9-]+\/select-account$/,
                    /\/school\/bacs\/direct-credit-payroll\/batches\/[a-f0-9-]+\/instructions$/,
                    /\/school\/bacs\/direct-credit-payroll\/batches\/[a-f0-9-]+\/review-approvals$/,
                    /\/school\/bacs\/direct-credit-payroll\/batches\/[a-f0-9-]+\/send-to-bureau$/,
                ],
                requiredOrganisationPermissions: [OrganisationPermissions.BACS],
            },
            {
                title: "Supplier Payments",
                iconPath: getImageUrl("icons/16x16/ic16-placeholder.svg"),
                route: "/school/bacs/direct-credit-supplier/batches",
                requiredPermissions: [Permissions.SUPPLIER],
                multiplePaths: true,
                matchPath: [
                    /\/school\/bacs\/direct-credit-supplier\/batches/,
                    /\/school\/bacs\/direct-credit-supplier\/batches\/[a-f0-9-]+\/batch-information$/,
                    /\/school\/bacs\/direct-credit-supplier\/batches\/[a-f0-9-]+\/select-account$/,
                    /\/school\/bacs\/direct-credit-supplier\/batches\/[a-f0-9-]+\/instructions$/,
                    /\/school\/bacs\/direct-credit-supplier\/batches\/[a-f0-9-]+\/review-approvals$/,
                    /\/school\/bacs\/direct-credit-supplier\/batches\/[a-f0-9-]+\/send-to-bureau$/,
                ],
                requiredOrganisationPermissions: [OrganisationPermissions.BACS],
            },
        ],
    },
    // {
    //     title: "Users",
    //     iconPath: getImageUrl("icons/24x24/ic24-users.svg"),
    //     route: "/school/users",
    // },
    {
        title: "Support",
        iconPath: getImageUrl("icons/24x24/ic24-help.svg"),
        route: "/school/support",
        requiredPermissions: [],
        matchPath: ["/school/support"],
        multiplePaths: false,
        requiredOrganisationPermissions: [],
    },
    {
        title: "Logout",
        iconPath: getImageUrl("icons/24x24/ic24-log-out.svg"),
        route: "logout",
        requiredPermissions: [],
        multiplePaths: false,
        requiredOrganisationPermissions: [],
    },
];

export const navRegistrationRoutes = [];

export const checkFirstTwoRouteSegmentsMatch = (
    navbarRoute: string,
    currentRoute: string,
) => {
    /**
     * Compares the navbar route and the current route the user is on and
     * checks that the first two segments match to highlight the correct section
     * in the sidebar navigation.
     * Eg expected output:
     * "/school/bacs/mandates" === "/school/bacs/files" returns true
     * "/school/transactions" === "school/transactions" return true
     * "/school/transactions" === "school/posting-report" return false
     * "/new-school/bacs/mandates" === "/school/bacs/files" returns false
     * "/school/transactions/mandates" === "/school/bacs/files" returns false
     * "/school/bacs/mandates" === "/school/transactions" returns false
     *
     * @param {string} navbarRoute - the route in the navigation sidebar menu
     * @param {string} currentRoute - the route the user is on
     * @returns {boolean}
     */

    // Remove leading and trailing slashes
    navbarRoute = navbarRoute.replace(/^\/|\/$/g, "");
    currentRoute = currentRoute.replace(/^\/|\/$/g, "");

    // Split strings into segments
    const segments1 = navbarRoute.split("/");
    const segments2 = currentRoute.split("/");

    // Check if both strings have at least two segments
    if (segments1.length < 2 || segments2.length < 2) {
        return false;
    }

    // Compare first two segments
    return segments1[0] === segments2[0] && segments1[1] === segments2[1];
};
