<template>
    <div
        :class="{
            'navbar-layout-container': display.mdAndUp,
            'navbar-layout-container mt-10': display.smAndDown,
        }"
    >
        <!-- Esenda logo/Hubpay logo -->
        <div class="navbar-logo-container">
            <!-- TODO update hubpay logo -->
            <v-img
                v-if="pStore.getIsHubpay"
                class="navbar-logo-hubpay-img"
                :src="getImageUrl('hubpay.svg')"
                alt="Hubpay logo"
            />
            <v-img
                v-else
                class="navbar-logo-esenda-img"
                :src="getImageUrl('esenda.png')"
                alt="Esenda logo"
            />
        </div>

        <!-- Menu list -->
        <v-list color="transparent" class="navbar-menu-items-container">
            <v-list-item v-for="(item, i) in filteredRoutes" :key="i">
                <div
                    :class="{
                        'navbar-menu-item-active': isParentItemActive(
                            item.route,
                        ),
                        'navbar-menu-item': !isParentItemActive(item.route),
                    }"
                    @click="
                        item.subitems
                            ? toggleSubItems(i)
                            : handleClick(item.route)
                    "
                >
                    <v-img
                        :class="{
                            'navbar-item-icon-active': isParentItemActive(
                                item.route,
                            ),
                            'navbar-item-icon': !isParentItemActive(item.route),
                        }"
                        :src="item.iconPath"
                    />
                    <span class="navbar-menu-item-text h200">{{
                        item.title
                    }}</span>
                    <img
                        v-if="item.subitems"
                        :class="{
                            'navbar-item-icon-arrow-expanded':
                                isSubmenuExpanded && expandedIndex === i,
                            'navbar-item-icon-arrow-collapsed':
                                !isSubmenuExpanded || expandedIndex !== i,
                        }"
                        :src="getImageUrl('icons/12x12/ic12-arrow-up.svg')"
                    />
                </div>
                <!-- Subitems -->
                <v-list
                    v-if="
                        item.subitems &&
                        isSubmenuExpanded &&
                        expandedIndex === i
                    "
                    color="transparent"
                >
                    <v-list-item v-for="(subitem, j) in item.subitems" :key="j">
                        <div
                            :class="{
                                'navbar-submenu-item-active':
                                    isSubItemActive(subitem),
                                'navbar-submenu-item':
                                    !isSubItemActive(subitem),
                            }"
                            @click="handleClick(subitem.route)"
                        >
                            <v-img
                                :src="subitem.iconPath"
                                :class="{
                                    'navbar-submenu-item-icon-active':
                                        isSubItemActive(subitem),
                                    'navbar-submenu-item-icon':
                                        !isSubItemActive(subitem),
                                }"
                            />
                            <span class="navbar-menu-item-text h200">{{
                                subitem.title
                            }}</span>
                        </div>
                    </v-list-item>
                </v-list>
            </v-list-item>
        </v-list>
    </div>
</template>

<script setup lang="ts">
import { useRouter, useRoute } from "vue-router";
import { computed, ref, watch, onMounted } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useDisplay } from "vuetify";
import { usePiniaStore } from "@/stores/store";
import {
    navSchoolRoutes,
    navRegistrationRoutes,
    checkFirstTwoRouteSegmentsMatch,
} from "@/methods/navbarRoutes";
import { Roles } from "@/constants/access";
import { getImageUrl } from "@/methods/iconHelpers";
import type { Route } from "@/types/routes";
import { OrganisationPermissions } from "@/constants/access";

const pStore = usePiniaStore();
const { logout } = useAuth0();
const router = useRouter();
const route = useRoute();
const display = ref(useDisplay());
const permissions = computed(() => pStore.getPermissions);

const schoolRoutes: Route[] = navSchoolRoutes;
const registrationRoutes: Route[] = navRegistrationRoutes;
const expandedIndex = ref<number | null>(null);
const isSubmenuExpanded = ref(false);

const school = computed(() => pStore.getSchoolSettings);
const hasOrganisationRole = computed(() =>
    pStore.getUserRoles.includes(Roles.ORGANISATION_ROLE),
);

const hasPermissions = (requiredPermissions) => {
    /** Check if the user has all required permissions */
    return requiredPermissions.every((permission) =>
        permissions.value.includes(permission),
    );
};

const filteredRoutes = computed(() => {
    let filtered = hasOrganisationRole.value
        ? schoolRoutes
        : registrationRoutes;

    if (hasOrganisationRole.value) {
        // TODO Filter against OrganisationPermissions list once this is in place
        if (!school.value.uses_wcbs) {
            filtered = filtered.filter(
                (obj) =>
                    !obj.requiredOrganisationPermissions.includes(
                        OrganisationPermissions.WCBS,
                    ),
            );
        }
        if (pStore.getSchoolShortcodes[0] !== "sandroyd") {
            filtered = filtered.filter((obj) => obj.title !== "Pass finance");
        }

        // TODO Filter against OrganisationPermissions list once this is in place
        if (!school.value.uses_bacs) {
            filtered = filtered.filter(
                (obj) =>
                    !obj.requiredOrganisationPermissions.includes(
                        OrganisationPermissions.BACS,
                    ),
            );
        }

        /** Filter out routes based on the requiredPermissions */
        filtered = filtered.filter((route) => {
            // Check the main route's permissions
            if (
                route.requiredPermissions.length === 0 ||
                hasPermissions(route.requiredPermissions)
            ) {
                // Filter subitems if they exist
                if (route.subitems) {
                    route.subitems = route.subitems.filter((subitem) => {
                        // Check if the subitem has OrganisationPermissions.BACS in requiredOrganisationPermissions
                        const requiresBacs =
                            subitem.requiredOrganisationPermissions &&
                            subitem.requiredOrganisationPermissions.includes(
                                OrganisationPermissions.BACS,
                            );

                        // Ensure subitems requiring BACS are filtered out if uses_bacs is false
                        if (!school.value.uses_bacs && requiresBacs) {
                            return false; // Filter out subitem if uses_bacs is false and it requires BACS
                        }

                        // If uses_bacs is true or the subitem doesn't require BACS, check requiredPermissions
                        return (
                            subitem.requiredPermissions.length === 0 ||
                            hasPermissions(subitem.requiredPermissions)
                        );
                    });
                }
                return true;
            }
            return false;
        });
    }
    return filtered;
});

const path = computed(() => route.path);

const handleClick = (path: string) => {
    if (path === "logout") {
        handleLogout();
    } else {
        changeRoute(path);
    }
};

const changeRoute = (path: string) => {
    router.push(path).catch((err) => {
        console.log("Route error: " + err);
    });
};

const toggleSubItems = (index: number) => {
    expandedIndex.value = expandedIndex.value === index ? null : index;
    isSubmenuExpanded.value = expandedIndex.value !== null;
};

const isParentItemActive = (itemRoute: string) => {
    return checkFirstTwoRouteSegmentsMatch(itemRoute, path.value);
};

const isSubItemActive = (subitem) =>
    subitem.multiplePaths
        ? subitem.matchPath.some((pattern) => pattern.test(path.value))
        : path.value === subitem.route;

const handleLogout = () =>
    logout({
        logoutParams: {
            returnTo: window.location.origin,
        },
    });

// Ensure submenu is expanded when component mounts if the current route is a subitem route
onMounted(async () => {
    setTimeout(() => {
        filteredRoutes.value.forEach((item, index) => {
            if (
                item.subitems &&
                item.subitems.some((subitem) => subitem.route === path.value)
            ) {
                expandedIndex.value = index;
                isSubmenuExpanded.value = true;
            }
        });
    }, 1000);
});

watch(path, () => {
    // Close submenu if the current path doesn't match any of the submenu routes
    if (expandedIndex.value !== null && !isSubmenuExpanded.value) {
        expandedIndex.value = null;
    }
});
</script>

<style lang="css" scoped>
.v-list {
    padding: 0 !important;
}
</style>
