<template>
    <button :class="btnStyle" @click="executeMethod" :disabled="disabled">
        <img role="img" :src="iconSrc" />
    </button>
</template>

<script setup lang="ts">
import { defineEmits } from "vue";

defineProps<{
    btnStyle?: string;
    disabled?: boolean;
    iconSrc?: any; // TODO add behaviour
}>();

const emit = defineEmits(["runMethod"]);
const executeMethod = () => {
    emit("runMethod");
};
</script>
