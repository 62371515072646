import { RouteRecordRaw } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";

const adminRoutes: Array<RouteRecordRaw> = [
    {
        path: "/admin",
        name: "Admin",
        component: () => import("@/views/admin/AdminLayout.vue"),
        beforeEnter: authGuard,
        meta: {
            admin: true,
            school: false,
        },
        // all the child routes will be rendered inside AdminLayout's <router-view>
        // and will be available under the /admin/ namespace
        children: [
            {
                path: "overview",
                name: "AdminOverview",
                component: () => import("@/views/admin/overview/Overview.vue"),
                beforeEnter: authGuard,
                meta: {
                    admin: true,
                    school: false,
                },
            },
            {
                path: "unmatched-webhooks-list",
                name: "UnmatchedWebhooksList",
                component: () =>
                    import(
                        "@/views/admin/reconciliation/webhooks/UnmatchedWebhooksList.vue"
                    ),
                beforeEnter: authGuard,
                meta: {
                    admin: true,
                    school: false,
                },
            },
            {
                path: "unmatched-webhook/:id",
                name: "UnmatchedWebhook",
                component: () =>
                    import(
                        "@/views/admin/reconciliation/webhooks/UnmatchedWebhook.vue"
                    ),
                beforeEnter: authGuard,
                meta: {
                    admin: true,
                    school: false,
                },
                // all the child routes will be rendered inside UnmatchedWebhook's <router-view>
                // and will be available under the /admin/unmatched-webhook/:id/ namespace
                children: [
                    {
                        path: "suggested-transaction-matches",
                        name: "SuggestedTransactionMatches",
                        component: () =>
                            import(
                                "@/views/admin/reconciliation/webhooks/matching/SuggestedTransactionMatches.vue"
                            ),
                        beforeEnter: authGuard,
                        meta: {
                            admin: true,
                            school: false,
                        },
                    },
                    {
                        path: "manual-matching-webhook",
                        name: "ManualMatchingWebhook",
                        component: () =>
                            import(
                                "@/views/admin/reconciliation/webhooks/matching/ManualMatching.vue"
                            ),
                        beforeEnter: authGuard,
                        meta: {
                            admin: true,
                            school: false,
                        },
                    },
                ],
            },
            {
                path: "unmatched-transactions-list",
                name: "UnmatchedTxList",
                component: () =>
                    import(
                        "@/views/admin/reconciliation/transactions/UnmatchedTxList.vue"
                    ),
                beforeEnter: authGuard,
                meta: {
                    admin: true,
                    school: false,
                },
            },
            {
                path: "unmatched-transaction/:id",
                name: "UnmatchedTransaction",
                component: () =>
                    import(
                        "@/views/admin/reconciliation/transactions/UnmatchedTransaction.vue"
                    ),
                beforeEnter: authGuard,
                meta: {
                    admin: true,
                    school: false,
                },
                // all the child routes will be rendered inside UnmatchedTransaction's <router-view>
                // and will be available under the /admin/unmatched-transaction/:id/ namespace
                children: [
                    {
                        path: "suggested-invoice-matches",
                        name: "SuggestedInvoiceMatches",
                        component: () =>
                            import(
                                "@/views/admin/reconciliation/transactions/matching/SuggestedInvoiceMatches.vue"
                            ),
                        beforeEnter: authGuard,
                        meta: {
                            admin: true,
                            school: false,
                        },
                    },
                    {
                        path: "suggested-billing-account-matches",
                        name: "SuggestedBillingAccountMatches",
                        component: () =>
                            import(
                                "@/views/admin/reconciliation/transactions/matching/SuggestedBillingAccountMatches.vue"
                            ),
                        beforeEnter: authGuard,
                        meta: {
                            admin: true,
                            school: false,
                        },
                    },
                    {
                        path: "manual-matching-transaction",
                        name: "ManualMatchingTransaction",
                        component: () =>
                            import(
                                "@/views/admin/reconciliation/transactions/matching/ManualMatching.vue"
                            ),
                        beforeEnter: authGuard,
                        meta: {
                            admin: true,
                            school: false,
                        },
                    },
                ],
            },
            {
                path: "create-organisation",
                name: "CreateOrganisation",
                component: () =>
                    import("@/views/admin/tools/CreateOrganisation.vue"),
                beforeEnter: authGuard,
                meta: {
                    admin: true,
                    school: false,
                },
            },
        ],
    },
];

export default adminRoutes.map((route) => {
    return { ...route };
});
