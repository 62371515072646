<template>
    <v-snackbar
        v-model="model"
        :color="getAlertColor"
        data-cy="alert"
        :timeout="3000"
    >
        <span
            v-if="alertParameters && Object.keys(alertParameters).length > 0"
            class="white-text"
            >{{ t(alertMessage, alertParameters) }}</span
        >
        <span v-else class="white-text">{{ t(alertMessage) }}</span>
        <template #actions>
            <img
                :src="getImageUrl('icons/20x20/ic20-close.svg')"
                class="pointer-cursor mr-1"
                @click="closeAlert"
            />
        </template>
    </v-snackbar>
</template>

<script setup lang="ts">
import { computed, defineEmits } from "vue";
import { getImageUrl } from "@/methods/iconHelpers";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const props = defineProps<{
    modelValue: boolean;
    alertMessage: string;
    alertColor?: string;
    alertParameters?: any;
}>();

const emit = defineEmits(["update:modelValue"]);

const model = computed({
    get: () => props.modelValue,
    set: (value) => {
        emit("update:modelValue", value);
    },
});

const closeAlert = () => {
    emit("update:modelValue", false);
};

const getAlertColor = computed(() => {
    let color;
    switch (props.alertColor) {
        case "error":
            color = "#F74141";
            break;
        case "success":
            color = "#159D50";
            break;
        case "warning":
            color = "#B36F20";
            break;
    }
    return color;
});
</script>
