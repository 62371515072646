export const getImageUrl = (filePath: string) => {
    /**
     * Returns the path of an image from the assets folder
     * @param {string} filePath - the path name (eg 'icons/ic20-more-hor.svg')
     * @returns {string}
     */
    return new URL(`../assets/${filePath}`, import.meta.url).href;
};

export const getChipColor = (
    selectedStatus: string,
    status: string,
    hover: boolean | undefined,
) => {
    /**
     * Returns different colors based on whether the status is selected
     * or hovered
     * @param {string} selectedStatus
     * @param {string} status - status from the list
     * @param {boolean | undefined} hover
     * @returns {string}
     * */
    if (!hover && status !== selectedStatus) {
        return "#ffffff";
    } else if (hover && status !== selectedStatus) {
        return "#0DA7F8";
    } else {
        return "#005B8A";
    }
};

export const getChipVariant = (selectedStatus: string, status: string) => {
    /**
     * Returns different variants based on whether the status is selected
     * @param {string} selectedStatus
     * @param {string} status
     * @returns {string}
     * */
    return status === selectedStatus ? "flat" : "outlined";
};
