/**
 * Error handling methods
 */
import { ref, Ref } from "vue";

/**
 * Alert configuration object
 *  */
export const alertConfiguration: Ref<{
    color: string;
    message: string;
    visible: boolean;
    alertParameters: any;
}> = ref({
    color: "",
    message: "",
    visible: false,
    alertParameters: {},
});

export const showAlert = (
    message: string,
    type = "error",
    alertParameters = {},
) => {
    /**
     * Sets the error alert configuration
     * @param  {[string]} message
     * @param {[string]} type - error/success/warning, defaults to error
     * @param {[any]} alertParameters - holds dynamic parameters to be passed to the internationalised message
     * @return {[void]} void
     */
    alertConfiguration.value.color = type;
    alertConfiguration.value.message = message;
    alertConfiguration.value.visible = true;
    alertConfiguration.value.alertParameters = alertParameters;
};
