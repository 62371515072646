<template>
    <v-dialog
        v-model="showPopUp"
        persistent
        data-cy="accessUpdatedPopUp"
        max-width="550"
    >
        <div class="grid-item">
            <v-row
                class="ma-0 px-0 pb-2 pt-0 border-bottom center-container-vertically"
            >
                <p class="h400 center-align-text">
                    {{ t("alerts.authenticationRequired") }}
                </p>
            </v-row>

            <v-row class="ma-0 px-0 pt-2">
                {{ t("alerts.accessUpdated") }}
            </v-row>

            <v-row
                class="mx-0 mt-2 px-0 pt-2 pb-4 d center-container-vertically"
            >
                <default-button
                    btn-style="default-btn small-default-btn primary-btn"
                    @run-method="handleLogout()"
                    data-cy="confirm"
                    text-style="h200 white-text"
                    text="Log out"
                />
            </v-row>
        </div>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useAuth0 } from "@auth0/auth0-vue";
import defaultButton from "@/components/buttons/defaultButton.vue";

const { t } = useI18n();
const { logout } = useAuth0();

const props = defineProps<{
    dialogVisible: boolean;
}>();
const showPopUp = ref(props.dialogVisible);

/** allow the dialog to close/open from parent component */
watch(
    () => props.dialogVisible,
    (newValue) => {
        showPopUp.value = newValue;
    },
);

const handleLogout = () => {
    logout({
        logoutParams: {
            returnTo: window.location.origin,
        },
    });
};
</script>
