<template>
    <!-- search input -->
    <div class="search-container">
        <form data-cy="searchForm" onsubmit="return false">
            <left-icon-text-input
                v-model="invoiceNumber"
                data-cy="searchByInvoiceNumber"
                style-class="medium-left-inner-icon-input"
                placeholder="Search by invoice number"
                :icon-src="getImageUrl('icons/16x16/ic16-search.svg')"
            />
            <button
                type="submit"
                style="display: none"
                id="search"
                @click="searchByInvoiceNumber()"
            ></button>
        </form>
    </div>
    <v-spacer></v-spacer>
    <div class="user-container mr-3">
        <!-- user logo -->
        <v-img class="user-logo" :src="userPicture"></v-img>
        <!-- user details -->
        <div class="user-details-container">
            <div data-cy="userName" class="user-name body-medium white-text">
                {{ userName }}
            </div>
            <div data-cy="school" class="user-role body-small secondary-text">
                {{ schoolName }}
            </div>
        </div>
        <!-- settings button -->
        <div class="settings-container">
            <v-menu>
                <template #activator="{ props }">
                    <icon-button
                        data-cy="userMenuButton"
                        btn-style="default-btn medium-icon-btn transparent-btn"
                        :icon-src="getImageUrl('icons/20x20/ic20-more-hor.svg')"
                        v-bind="props"
                    />
                </template>
                <v-list class="user-menu" data-cy="userMenu">
                    <v-list-item
                        data-cy="menuSettingsButton"
                        class="user-menu-item"
                        @click="changeRoute('/school/settings')"
                    >
                        <template #prepend>
                            <img
                                class="mr-4"
                                :src="
                                    getImageUrl('icons/20x20/ic20-settings.svg')
                                "
                            />
                        </template>
                        <v-list-item-title class="mr-2 body-medium white-text"
                            >Settings</v-list-item-title
                        >
                    </v-list-item>
                    <v-list-item
                        data-cy="menuLogoutButton"
                        class="user-menu-item"
                        @click="handleLogout()"
                    >
                        <template #prepend>
                            <img
                                class="mr-4"
                                :src="
                                    getImageUrl('icons/20x20/ic20-log-out.svg')
                                "
                            />
                        </template>
                        <v-list-item-title class="mr-2 body-medium white-text"
                            >Logout</v-list-item-title
                        >
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useAuth0 } from "@auth0/auth0-vue";
import { ref, computed } from "vue";
import leftIconTextInput from "@/components/forms/inputs/leftIconTextInput.vue";
import iconButton from "@/components/buttons/iconButton.vue";
import { useRouter } from "vue-router";
import { usePiniaStore } from "../../stores/store";
import { getImageUrl } from "@/methods/iconHelpers";

const router = useRouter();
const pStore = usePiniaStore();

// gets current user
const { user } = useAuth0();
const { logout } = useAuth0();

// get user details
const userPicture = computed(() => (user.value ? user.value.picture : ""));
const userName = computed(() => (user.value ? user.value.name : ""));
const schoolName = computed(() => pStore.getSchoolSettings.name);

// searches for invoice on enter
const invoiceNumber = ref("");
const searchByInvoiceNumber = () => {
    const path = `/school/invoices?invoiceNumber=${invoiceNumber.value}`;
    router.push(path).catch((err) => {
        console.log("Route error: " + err);
    });
};

const changeRoute = (path: string) => {
    router.push(path).catch((err) => {
        console.log("Route error: " + err);
    });
};

// logs user out
const handleLogout = () =>
    logout({
        logoutParams: {
            returnTo: window.location.origin,
        },
    });
</script>
