export const areArraysEquivalent = (arr1: any[], arr2: any[]): boolean => {
    /**
     * Compares two arrays for equality, ignoring the order of elements.
     * Returns `true` if the arrays contain the same elements regardless
     * of order, otherwise `false`.
     * @param {any[]} arr1
     * @param {any[]} arr2
     * @returns {boolean}
     */

    if (arr1.length !== arr2.length) return false;

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    return sortedArr1.every((value, index) => value === sortedArr2[index]);
};

export const sortArrayByKeyAlphabetically = (list: any[], keyName: string) => {
    /**
     * Sorts an array of objects alphabetically based on the specified key.
     * @param {any[]} list the array of objects to be sorted.
     * @param {string} keyName the key of the object by which to sort the array.
     * @returns {any[]} the sorted array, ordered alphabetically by the given key's value.
     */
    return list.sort((a, b) => a[keyName].localeCompare(b[keyName]));
};
