import { RouteRecordRaw } from "vue-router";

const authRoutes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "LoginEsenda",
        component: () => import("@/views/auth/login/LoginEsenda.vue"),
        meta: {
            admin: false,
            school: false,
        },
    },
    {
        path: "/unauthorised-access",
        name: "UnauthorisedAccess",
        component: () => import("@/views/auth/UnauthorisedAccess.vue"),
        meta: {
            admin: false,
            school: false,
        },
    },
    {
        path: "/callback",
        name: "callback",
        component: () => import("@/views/auth/login/Callback.vue"),
        meta: {
            admin: false,
            school: false,
        },
    },
    {
        path: "/login-redirect",
        name: "auth0profile",
        component: () => import("@/views/auth/login/LoginRedirect.vue"),
        meta: {
            admin: false,
            school: false,
            shouldRedirect: true,
        },
    },
    {
        path: "/:catchAll(.*)",
        name: "404",
        component: () => import("@/views/auth/404.vue"),
        meta: {
            admin: false,
            school: false,
        },
    },
];

export default authRoutes.map((route) => {
    return { ...route };
});
