<template>
    <button
        :class="btnStyle"
        @click="executeMethod"
        :type="type"
        :disabled="isDisabled"
    >
        <span v-if="btnLoading">
            <div class="lds-dual-ring"></div>
        </span>
        <span v-else :class="textStyle">
            {{ text }}
        </span>
    </button>
</template>

<script setup lang="ts">
import { defineEmits } from "vue";

defineProps<{
    btnStyle?: string;
    text?: string;
    textStyle?: string;
    disabled?: boolean;
    btnLoading?: boolean;
    type?: any;
    isDisabled?: boolean;
}>();

const emit = defineEmits(["runMethod"]);
const executeMethod = () => {
    emit("runMethod");
};
</script>
